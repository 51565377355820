import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import clsx from 'clsx';

import Dropdown from './dropdown'
import siteLogo from "../images/getonboard.ly@2x.png"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const ref = useRef(null)

  const headerOnScroll = () => {
    document.getElementsByTagName("body")[0].onscroll = e => {
      if (window.scrollY <= 15) {
        document
          .getElementsByTagName("header")[0]
          .classList.remove("header-onscroll")
      } else {
        document
          .getElementsByTagName("header")[0]
          .classList.add("header-onscroll")
      }
    }
  }


  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsShown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    headerOnScroll();
  })

  const planData = {
    billsby: {
      productId: 6352,
      planId: 8794,
      cycleId: 11339,
    },
    chargebee: {
      productId: 6353,
      planId: 8796,
      cycleId: 11341,
    },
  }

  return (
    <header
      className={clsx("header",
        { "header-menu-open": isOpen }
      )}
      ref={ref}>
      <div className="container">
        <div className="header-wrapper">
          <Link to="/" className="logo-link">
            <img className="logo" src={siteLogo} alt="Logo" />
          </Link>
          <div
            className={clsx(
              "menu-wrapper",
              { "menu-open": isOpen }
            )}
          >
            <div className="menu-link-wrapper">
              <ul className="links-wrapper">
                <li>
                  <Link
                    to="/"
                    className="header-link"
                    getProps={({ isCurrent }) => {
                      return isCurrent ? { className: "header-link is-active" } : null
                    }}
                    activeClassName="is-active">
                    Product
                    </Link>
                </li>
                <li>
                  <Link
                    to="/pricing"
                    className="header-link"
                    getProps={({ isCurrent }) => {
                      return isCurrent ? { className: "header-link is-active" } : null
                    }}
                    activeClassName="is-active">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/workswith"
                    className="header-link"
                    getProps={({ isCurrent }) => {
                      return isCurrent ? { className: "header-link is-active" } : null
                    }}
                    activeClassName="is-active">
                    Works with
                    </Link>
                </li>
              </ul>
              <div className="sign-in-wrapper">
                <a
                  href="https://getonboard-ly-app.onrender.com/login"
                  className="header-link"
                  activeClassName="is-active"
                >
                  Login
                </a>
                <div className="btn-holder">
                  <button
                    className="btn-purple"
                    onClick={() => setIsShown(!isShown)}>
                    Start your one month free trial
                  </button>
                  {isShown && (
                    <Dropdown data={planData} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            className={clsx(
              "toggle-menu burger-close",
              { "burger-open": isOpen }
            )}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
      </div>
    </header>
  )
}


export default Header