import React, { useEffect } from "react"
import runScanDom from "../lib/billsbyScanDom"

const Dropdown = ({ data }) => {
  const redirectURL = "https://getonboard-ly-app.onrender.com/registration"
  // const redirectURL = "https://app.getonboard.ly/registration"

  useEffect(() => {
    runScanDom()
  }, [])

  return (
    <div className="signup-dropdown">
      <p>Which subscription billing platform do you use?</p>
      <div className="signup-btn-holder">
        <a
          href="javascript:void(0)"
          data-billsby-type="checkout"
          data-billsby-product={data.billsby.productId}
          data-billsby-plan={data.billsby.planId}
          data-billsby-cycle={data.billsby.cycleId}
          data-billsby-redirect={redirectURL}
          className="btn-purple"
        >
          Billsby
        </a>
        <a
          href="javascript:void(0)"
          data-billsby-type="checkout"
          data-billsby-product={data.chargebee.productId}
          data-billsby-plan={data.chargebee.planId}
          data-billsby-cycle={data.chargebee.cycleId}
          data-billsby-redirect={redirectURL}
          className="btn-purple"
        >
          Chargebee
        </a>
      </div>
    </div>
  )
}

export default Dropdown
