/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const openTrengo = () => {
  window.Trengo = window.Trengo || {};
  window.Trengo.key = 'wlhXRDJXk5pIyiTHrWOE';
  (function(d, script, t) {
      script = d.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://static.widget.trengo.eu/embed.js';
      d.getElementsByTagName('head')[0].appendChild(script);
  }(document));
}

const Layout = ({ children, className }) => {
  useEffect(() => {
    openTrengo()
  })
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
        <main className={className}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
